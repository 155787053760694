<template>
    <bread-pages>
        <div class="main" v-loading="loading">
            <div class="title">{{ info.name }}</div>
            <div class="content">
                <div class="sub-title">需求项</div>
                <div class="info">
                    <div class="info-item">
                        <span class="text1">{{ `${info.type == 'COPY' ? '作品类型' : '软著类型'}` }}：</span>
                        <span class="text2">{{ info.workName }}</span>
                    </div>

                    <div class="info-item">
                        <span class="text1">交易方式：</span>
                        <span class="text2">{{ getLabelName(info.mode, tradingMethodOption) }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">预算：</span>
                        <span class="text2" v-if="info.negotiateDirectly">面议 </span>
                        <span class="text2" v-else>{{ info.expectedPrice }}万元 </span>
                    </div>
                    <div class="info-item">
                        <span class="text1">发布时间:</span>
                        <span class="text2">{{ formatTime(info.createdAt, 'YYYY-MM-DD') }}</span>
                    </div>
                    <!-- <div class="info-item">
                        <span class="text1">联系人：</span>
                        <span class="text2">{{ info.contact }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">所在地区：</span>
                        <span class="text2">{{ info.address }}</span>
                    </div> -->
                </div>

                <div class="sub-title">需求描述</div>
                <div class="text">{{ info.description }}</div>
                <div class="btn">
                    <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small">
                        <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                        咨询
                    </el-button>
                    <el-button @click.prevent="postMessage" type="primary" class="icon-button" size="small">
                        <i class="iconfont iconfont-nav_icon_xiaoxi"></i>留言
                    </el-button>
                </div>
            </div>
        </div>
    </bread-pages>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
import BreadPages from '../../components/page/BreadPages.vue';
import comEvent from '../../mixins/comEvent';
import {
    patentTypeOptions,
    lawStatusOptions,
    ownerTypeOptions,
    tradingMethodOption,
    commissionTypeOptions,
    copyrightType
} from '../../utils/variables';
export default {
    data() {
        return {
            info: {},
            patentTypeOptions,
            lawStatusOptions,
            ownerTypeOptions,
            tradingMethodOption,
            commissionTypeOptions,
            loading: true
        };
    },
    components: {
        BreadPages
    },
    mixins: [comEvent],
    mounted() {
        this.loading = true;
        this.$http.get('/copyrightDemand/get/' + this.$route.query.id).then(res => {
            console.log(res);
            this.info = res;
            setTimeout(() => {
                this.loading = false;
            }, 500);

            let info = copyrightType.find(item => {
                return item.value === res.type;
            });
            this.$EventBus.$emit('changePreTitle', {
                name: info.label,
                path: '/copyrightDemand',
                query: {
                    type: res.type
                }
            });
            this.$EventBus.$emit('changeTitle', info.label + '详情');
            document.title = info.label + '详情';
        });
    },
    created() {
        let addView = {
            id: this.$route.query.id,
            type: 'CopyrightDemand'
        };
        // console.log(addView);
        this.loading = true;
        this.$http.get('/all/addView/', addView).then(res => {
            console.log(res);
        });
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    padding: 0 30px 150px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: @warn;
    line-height: 34px;
    text-align: center;
    padding: 50px 0 20px;
    border-bottom: 1px solid @bg;
    // margin: 0 70px;
    border-bottom: 1px solid @bg;
}

.sub-title {
    font-size: 16px;
    font-weight: bold;
    color: @warn;
    line-height: 22px;
    padding: 50px 0 16px;
    .flex();
    &::before {
        content: '';
        width: 5px;
        height: 16px;
        background: @warn;
        border-radius: 3px;
        margin-right: 10px;
    }
}

.info {
    .flex();
    flex-wrap: wrap;
    .info-item {
        width: 45%;
        padding-right: 50px;
        box-sizing: border-box;
        line-height: 32px;
        margin-bottom: 8px;
        .flex();

        .text1 {
            font-size: 14px;
            color: #999999;
            display: inline-block;
            min-width: 72px;
            flex-shrink: 0;
        }

        .text2 {
            font-size: 14px;
            color: #000;
            margin-left: 6px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:nth-child(2n) {
            .text1 {
                min-width: 98px;
            }
        }
    }
}
.btn {
    margin-top: 173px;
    text-align: center;
    .icon-button {
        width: 134px;
        line-height: 36px;
        border-radius: 2px;
        border: 1px solid #01a041;
        background: #FFFFFF;
        color: #01A041;
        justify-content: center;
    }
}
.appContainer {
    background: #ffffff !important;
}
.text {
    font-size: 14px;
    color: #000000;
    line-height: 20px;
}
</style>
